import React from "react";
import styled from "styled-components";
import Section from "../components/Section";
import {inject, observer} from "mobx-react";
import stores from "../stores";
import TranslationStore, {
    T_HOME_MY_TABLE,
    T_SEARCH_CLOSE,
    T_TABLE_TOTAL,
    T_MISC_MIDNIGHT_PRICES,
    T_TABLE_EMPTY,
} from "../stores/translation";
import HomeStore from "../stores/Home";
import ApplicationStore from "../stores/Application";
import Item from "../models/Item";
import SimpleCard from "../components/SimpleCard";
import CloseIcon from "react-feather/dist/icons/x";
import emptyTable from "../assets/empty-table.png";
import fullTable from "../assets/full-table.png";

interface Props {
    onClose: Function;
}

interface StoreProps extends Props {
    translation: TranslationStore | null,
    home: HomeStore | null,
    application: ApplicationStore | null
}

@inject(stores.translation, stores.home)
@observer
class Table extends React.Component<Props> {
    get injected() {
        return this.props as StoreProps;
    }

    handleItemSelection = (id: number) => {
        this.injected.home?.toggleProductSelection(id);
    }

    render() {
        const products = this.injected.home?.products;

        return <Container>
            <Actions>
                <Close onClick={() => this.props.onClose()}>
                    <span>{this.injected?.translation?.get(T_SEARCH_CLOSE)}</span>
                    <CloseIcon size={16}/>
                </Close>
            </Actions>
            <Results>
                <Section title={this.injected?.translation?.get(T_HOME_MY_TABLE)} />
                {products && products.map((i: Item) =>
                    <SimpleCard key={i.id}
                                id={i.id!.toString()}
                                title_en={i.title_en}
                                title_sl={i.title_sl}
                                subtitle_en={i.subtitle_en}
                                subtitle_sl={i.subtitle_sl}
                                price={i.price!.toFixed(2)}
                                onClick={() => {}}
                                onRemove={() => this.handleItemSelection(i.id!)}
                                quantity={this.injected?.home?.productsQuantity[i.id!]}
                                onQuantityChange={(qty: number) => {
                                    this.injected?.home?.updateProductsQuantity(i.id!, qty);
                                }}
                    />
                )}
            </Results>
            { 
                this.injected?.home?.products.length === 0 && <Empty>
                    <EmptyImage src={emptyTable} alt="Your table is empty, add some articles" />
                    {this.injected?.translation?.get(T_TABLE_EMPTY)}
                </Empty>
            }
            {(this.injected?.home?.products.length ?? 0) > 0 && <React.Fragment>
                    <TotalRow>
                        <TotalText>{this.injected?.translation?.get(T_TABLE_TOTAL)}:</TotalText>
                        <TotalValue>{this.injected.home?.total?.toFixed(2)} €</TotalValue>
                    </TotalRow>    
                    <Notice>{this.injected?.translation?.get(T_MISC_MIDNIGHT_PRICES)}</Notice>
                    <FullTable src={fullTable} alt="You table has some items" />
                </React.Fragment>
            }
        </Container>
    }
}

export default Table;

const Container = styled.div`
    position: relative;
`;

const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.app.primary};
  border-radius: 6px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 1rem;
  border: 2px solid ${p => p.theme.app.primary};
  padding: 5px 0.5rem;
  user-select: none;
  
  span {
    margin-right: 1rem;
    vertical-align: middle;
    font-weight: bold;
  }
`;

const Results = styled.div``;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${p => p.theme.app.primary};
  font-size: 20px;
  user-select: none;
`;

const TotalText = styled.div`
`;

const TotalValue = styled.div`
  font-weight: bold;
  margin-left: 10px;
`;

const Notice = styled.div`
  font-size: 14px;
  color: ${p => p.theme.app.secondary};
  margin-top: 2rem;
  text-align: center;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.app.secondary};
  margin: 2rem 0;
  text-align: center;
  user-select: none;
`;

const EmptyImage = styled.img`
  width: 120px;
  height: auto;
  opacity: 0.4;
  margin-bottom: 2rem;
  pointer-events: none;
`;

const FullTable = styled.img`
    width: 140px;
    height: auto;
    opacity: 0.1;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 4;
    pointer-events: none;
`;

