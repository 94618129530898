class Category {
    id?: number | null;
    title_en?: string;
    description_en?: string;
    title_sl?: string;
    description_sl?: string;
    order?: number;
    status?: boolean;
    promoted?: boolean;
    parent?: number | null;

    fromJSON(obj: Object) {
        let result: Category = new Category();
        for (let index in obj) {
            if (result.hasOwnProperty(index)) {
                // @ts-ignore
                result[index] = obj[index]; // care, has to be result
            }
        }
        return result;
    }
}

export default Category;
