import React from "react";
import rudderIcon from "../assets/rudder.svg";
import styled from "styled-components";

const Spinner = () => (
    <Container>
        <div className="spinner">
            <img width={50} height={50} src={rudderIcon} alt=" " />
        </div>
    </Container>
)

export default Spinner;

const Container = styled.div`
  color: ${p => p.theme.app.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;
