import React from "react";
import styled from "styled-components";

interface Props {
    data: any;
    height: string;
    itemWidth?: string;
}

interface ContainerProps {
    height: string;
    itemWidth?: string;
}

class HorizontalScroll extends React.Component<Props> {
    render() {
        return <Container height={this.props.height} >
            <InnerContainer itemWidth={this.props.itemWidth} height={this.props.height}>{this.props.data}</InnerContainer>
        </Container>;
    }
}

export default HorizontalScroll;

const Container = styled.div<ContainerProps>`
  height: ${p => p.height};
  width: 100%;
  position: relative;
  //z-index: 4;
  overflow: hidden;
  //margin-bottom: 1rem;
`;

const InnerContainer = styled.div<ContainerProps>`
  height: ${p => p.height};
  padding-bottom: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-right: 1rem;
  
   & > * {
    display: inline-block;
    width: ${p => p.itemWidth};
  }
`;
