import React from "react";
import styled from "styled-components";
import ApplicationStore, {Languages} from "../stores/Application";
import {inject, observer} from "mobx-react";
import stores from "../stores";

interface Props {
    onClick?: Function;
    title_en?: string;
    title_sl?: string;
    isSelected?: boolean;
}

interface StoreProps extends Props {
    application: ApplicationStore;
}


interface ContainerProps {
    onClick?: Function;
    isSelected?: boolean;
}

@inject(stores.application)
@observer
class FilterItem extends React.Component<Props> {
    static defaultProps = {isSelected: false}

    get injected() {
        return this.props as StoreProps;
    }

    render() {
        let {onClick, title_en, title_sl, isSelected} = this.props;

        let title = title_en;
        if (this.injected.application.language === Languages.slovenian) {
            title = title_sl;
        }

        return (
            <Container onClick={() => onClick && onClick()} isSelected={isSelected}>
                <Title>{title}</Title>
            </Container>
        );
    }
}

export default FilterItem;

const Container = styled.div<ContainerProps>`
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding: 7px 1rem;
  user-select: none;
  transition: .4s all;
  background: ${p => p.theme.card.background};
  border-radius: 50px;
  font-size: 0.8rem;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  
  ${p => p.isSelected && `background: ${p.theme.card.accent}`}
`;

const Title = styled.div`
  color: ${p => p.theme.card.primary};
  font-weight: bold;
`;
