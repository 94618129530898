import React from "react";
import styled from "styled-components";
import ArrowUp from "react-feather/dist/icons/plus-circle";
import ArrowDown from "react-feather/dist/icons/minus-circle";


interface IProps {
    quantity: number;
    onChange: Function;
}

const QuantityPicker: React.FC<IProps> = ({ quantity, onChange }) => {
    return <Container>
        <Control onClick={() => onChange(quantity+1)}>
            <ArrowUp size={25} />
        </Control>
        <Control onClick={() => onChange(quantity - 1 > 0 ? quantity-1 : 1)} disabled={quantity - 1 <= 0}>
            <ArrowDown size={25} />
        </Control>
    </Container>
}

export default QuantityPicker;
export { QuantityPicker }

const Container = styled.div`
    width: 80px;
    display: flex;
`;

const Control = styled.div<any>`
    color: ${p => p.theme.app.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    opacity: ${p => p.disabled && `0.4`};
`;