import React, {ReactNode} from "react";
import {ThemeProvider} from "styled-components";

const theme = {
    app: {
        background: "#191D32",
        backgroundLight: "#353747",
        primaryFontFamily: "SignPainter",
        secondaryFontFamily: "",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#15a7a7",
    },
    card: {
        background: "rgba(0,0,0,.3)",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#15a7a7",
        gradient: "background: linear-gradient(90deg, rgba(32,74,107,1) 0%, rgba(25,29,50,1) 100%);"
    }

};

const enkafe_theme = {
    app: {
        background: "#564833",
        backgroundLight: "#353747",
        primaryFontFamily: "SignPainter",
        secondaryFontFamily: "",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#191D32",
    },
    card: {
        background: "rgba(0,0,0,.3)",
        primary: "white",
        secondary: "rgba(255,255,255,0.7)",
        accent: "#191D32",
        gradient: "background: linear-gradient(90deg, #4a3f2b 0%, #2b2318 100%);"
    }

};

interface Props {
    children: ReactNode;
}

const CaptainsTheme:React.FC<Props> = ({ children }) => <ThemeProvider theme={window.isEnkafe ? enkafe_theme : theme}>{children}</ThemeProvider>;

export default CaptainsTheme;
export { theme, CaptainsTheme };
