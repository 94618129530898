import {observable} from "mobx";

enum Languages {
    english,
    slovenian
}

class ApplicationStore {
    @observable
    language: Languages = Languages.english;

    @observable
    fixedScreenIsOpen = false;

    constructor() {
        const l = localStorage.getItem('language') || "en";
        if (l === "en") {
            this.language = Languages.english;
        } else if (l === "sl") {
            this.language = Languages.slovenian;
        }

        if (true || window.location.host === 'menu.enkafe.si') {
            this.language = Languages.slovenian;
        }
    }


    changeLanguage = (language: Languages) => {
        this.language = language;
        localStorage.setItem('language', this.language === Languages.slovenian ? "sl" : "en");
    }
}

export { Languages };
export default ApplicationStore;
