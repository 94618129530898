import React from "react";
import errorIcon from "../assets/fragile.svg";
import styled from "styled-components";
import TranslationStore, {T_HOME_ERROR_RETRY} from "../stores/translation";
import {inject, observer} from "mobx-react";
import stores from "../stores";

interface Props {
    title?: string;
    onRetry?: Function;
}

interface StoreProps extends Props {
    translation: TranslationStore;
}

@inject(stores.translation)
@observer
class StateError extends React.Component<Props> {
    get injected() {
        return this.props as StoreProps;
    }

    render() {
        return (
            <Container>
                <img width={50} src={errorIcon} alt="Error"/>
                <Title>{this.props.title}</Title>
                <Button onClick={() => this.props.onRetry && this.props.onRetry()}>{this.injected.translation.get(T_HOME_ERROR_RETRY)}</Button>
            </Container>
        );
    }
}

export {StateError};

const Container = styled.div`
  color: ${p => p.theme.app.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;

const Title = styled.div`
  color: #e74c3c;
  margin-top: 1rem;
`;

const Button = styled.button`
  margin-top: 2rem;
  border-radius: 6px;
  background: #e74c3c;
  color: white;
  font-size: 1rem;
  padding: 8px 2rem;
  outline: none;
  border: none;
  cursor: pointer;
`;
