import React from "react";
import styled from "styled-components";
import AnchorIcon from "react-feather/dist/icons/anchor";
import FilterIcon from "react-feather/dist/icons/filter";

interface Props {
    onClick?: Function;
    title?: string;
    icon?: string;
    padding?: string;
}

const Section:React.FC<Props> = ({ onClick, title, icon, padding }) => (
    <Container onClick={() => onClick && onClick()} padding={padding}>
        {icon === "filter" ? <FilterIcon /> : <AnchorIcon />}
        <Title>{title}</Title>
    </Container>
);

export default Section;

const Container = styled.div<any>`
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  user-select: none;
  transition: .4s all;
  font-family: 'SignPainter',serif;
  font-size: 1.5rem;
  color: ${p => p.theme.app.primary};
  align-items: center;
  padding: ${p => p.padding};
`;

const Title = styled.div`
  padding-left: 0.8rem;
  color: ${p => p.theme.app.primary};
  padding-top: 5px;
`;
