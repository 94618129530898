import React from 'react';
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import HomeStore from "../stores/Home";
import Category from "../models/Category";
import Item from "../models/Item";
import { CellMeasurerCache } from "react-virtualized";
import { autorun, observable } from "mobx";
import TranslationStore, {
} from "../stores/translation";
import ApplicationStore from "../stores/Application";
import stores from "../stores";

interface Props {
}

interface StoreProps extends Props {
    translation: TranslationStore,
    home: HomeStore,
    application: ApplicationStore
}

@inject(stores.translation, stores.home, stores.application)
@observer
class Home extends React.Component<Props> {
    cache: CellMeasurerCache;

    @observable
    showSearch = false;

    @observable
    showTable = false;

    componentDidMount() {
        this.init();

        autorun(() => {
            document.body.style.overflow = (this.showTable || this.showSearch) ? "hidden" : "visible";
        });
    }

    constructor(props: any) {
        super(props);

        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        });
    }

    init = () => {
        this.injected.home.init();
    }

    get injected() {
        return this.props as StoreProps;
    }

    renderCategories() {
        const categories = this.injected.home.categoriesState.data.filter((i: Category) => !i.parent);

        return categories.map((i: Category) => (
            <CategoryItem key={i.id!}>
                <h3>{i.title_sl}</h3>
                {this.renderCategoryProducts(i.id)}
            </CategoryItem>
        ));
    }

    renderCategoryProducts(categoryId: Category['id']) {
        const subcategories: Array<Category> = this.injected.home.categoriesState.data.filter((i: Category) => i.parent === categoryId);
        const items = this.injected.home.itemsState.data.filter((i: Item) => i.category === categoryId);

        if (!subcategories.length) {
            return items.map((i: Item) => {
                return <MenuItem>
                    <Row>
                        <div>{i.title_sl}</div>
                        <div>{i.price} €</div>
                    </Row>
                    <MenuDescription>
                        {i.subtitle_sl}
                    </MenuDescription>
                </MenuItem>
            });
        }

        return subcategories.map(subcategory => {
            const items = this.injected.home.itemsState.data.filter((i: Item) => i.category === subcategory.id);

            return items.map((i: Item) => {
                return <MenuItem>
                    <Row>
                        <div>{i.title_sl}</div>
                        <div>{i.price} €</div>
                    </Row>
                    <MenuDescription>
                        {i.subtitle_sl}
                    </MenuDescription>
                </MenuItem>
            });
        })
    }

    render() {
        return (
            <Main>
                {this.renderCategories()}
            </Main>
        );
    }
}

const Main = styled.div`
    padding: 2rem;
    padding-bottom: 4rem;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

const MenuDescription = styled.div`
    font-size: 14px;
    color: gray;
    padding-right: 100px;
`

const MenuItem = styled.div`
    margin-bottom: 1rem;
`

const CategoryItem = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`

export default Home;