import React from 'react';
import CategoryItem from "../components/CategoryItem";
import Header from "../components/Header";
import FilterItem from "../components/FilterItem";
import styled from "styled-components";
import bgRudder from "../assets/bg_rudder.png";
import bgVessel from "../assets/bg_vessel.png";
import flagEN from "../assets/flag_uk.png";
import flagSL from "../assets/flag_slo.png";
import Section from "../components/Section";
import Spinner from "../components/Spinner";
import SimpleCard from '../components/SimpleCard';
import {inject, observer} from "mobx-react";
import HomeStore from "../stores/Home";
import Category from "../models/Category";
import Item from "../models/Item";
import {CellMeasurerCache} from "react-virtualized";
import Search from "./Search";
import {autorun, observable} from "mobx";
import TranslationStore, {
    T_HOME_BEST_SELLING, T_HOME_ERROR,
    T_HOME_FILTER,
    T_HOME_MY_TABLE
} from "../stores/translation";
import ApplicationStore, {Languages} from "../stores/Application";
import stores from "../stores";
import HorizontalScroll from "../components/HorizontalScroll";
import cocktail from "../assets/cocktail.svg";
import Table from "./Table";
import {StateError} from "../components/States";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

interface Props {
}

interface StoreProps extends Props {
    translation: TranslationStore,
    home: HomeStore,
    application: ApplicationStore
}

@inject(stores.translation, stores.home, stores.application)
@observer
class Home extends React.Component<Props> {
    cache: CellMeasurerCache;

    @observable
    showSearch = false;

    @observable
    showTable = false;

    componentDidMount() {
        this.init();

        autorun(() => {
            document.body.style.overflow = (this.showTable || this.showSearch) ? "hidden" : "visible";
        });
    }

    constructor(props: any) {
        super(props);

        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        });
    }

    init = () => {
        this.injected.home.init();
    }

    get injected() {
        return this.props as StoreProps;
    }

    renderCategories() {
        const categories = this.injected.home.categoriesState.data.filter((i: Category) => !i.parent);

        return <HorizontalScroll
            height={"50px"}
            data={categories.map((i: Category) => (
                <CategoryItem key={i.id!} title_en={i.title_en} title_sl={i.title_sl}
                              isSelected={this.injected?.home.selectedCategory === i.id}
                              onClick={() => {
                                  this.injected.home.selectedCategory = parseInt(i.id!.toString());
                                  this.injected.home.selectedFilter = -1;
                                  const page = window.location.pathname + "/category/" + i.title_en?.toLowerCase().split(" ").join("_")
                                  ReactGA.pageview(page);
                                  ReactGA4.send({ hitType: "pageview", page: page });
                              }}
                />
            ))}

        />;
    }

    renderFilter() {
        let subcategories: Array<Category> = this.injected.home.categoriesState.data.filter((i: Category) => i.parent === this.injected.home.selectedCategory);

        const all: Category = new Category();
        if (subcategories.length > 0) {
            all.title_en = "All";
            all.title_sl = "Vsi";
            all.id = -1;
            subcategories = [all, ...subcategories];
        }

        if (subcategories.length === 0) return null;

        return <React.Fragment>
            <Section title={this.injected?.translation?.get(T_HOME_FILTER)} icon="filter" padding="0 1rem"/>
            <HorizontalScroll
                height={"35px"}
                data={subcategories.map((i: Category) => (
                    <FilterItem key={i.id!} title_en={i.title_en} title_sl={i.title_sl} onClick={() => {
                        this.injected.home.selectedFilter = parseInt(i.id!.toString());

                        let selectedCategory: Category | undefined = this.injected?.home.categoriesState.data.find(c => c.id === this.injected?.home.selectedCategory);
                        
                        if (selectedCategory) {
                            const category =  "/category/" + selectedCategory.title_en?.toLowerCase().split(" ").join("_");
                            const filter = "/filter/" + i.title_en?.toLowerCase().split(" ").join("_");
                            const page = window.location.pathname + category + filter;
                            ReactGA.pageview(page);
                            ReactGA4.send({ hitType: "pageview", page: page });
                        }

                    }} isSelected={this.injected.home.selectedFilter === i.id}/>
                ))}
            />
            {this.renderSubcategoryDescription(this.injected.home.selectedFilter || -1)}
        </React.Fragment>;
    }

    handleItemSelection = (id: number) => {
        this.injected.home.toggleProductSelection(id);
    }

    isProductSelected = (id: number) => {
        return this.injected.home.isProductSelected(id);
    }

    renderCategoryDescription = () => {
        let category: Category | undefined;
        category = this.injected.home.categoriesState.data.find((c: Category) => c.id === this.injected.home.selectedCategory);

        if (category) {
            let description = category.description_en;

            if (this.injected.application.language === Languages.slovenian) {
                description = category.description_sl;
            }

            if (description?.length === 0) return null;

            return <CategoryDescription>{description}</CategoryDescription>
        }
        return null;
    }

    renderSubcategoryDescription = (id: number) => {
        let category: Category | undefined;
        category = this.injected.home.categoriesState.data.find((c: Category) => c.id === id);

        if (category) {
            let description = category.description_en;

            if (this.injected.application.language === Languages.slovenian) {
                description = category.description_sl;
            }

            if (description?.length === 0) return null;

            return <CategoryDescription>{description}</CategoryDescription>
        }
        return null;
    }

    renderPromoted = () => {
        let categories: (number | null | undefined)[];
        categories = this.injected.home.categoriesState.data.filter((c: Category) => c.parent === this.injected.home.selectedCategory).map((c) => c.id);

        let items: Array<Item>;
        items = this.injected.home.itemsState.data.filter((c: Item) => categories.indexOf(c.category) !== -1 && c.promoted);

        if (items.length > 0) {
            return <div>
                <Section title={this.injected?.translation?.get(T_HOME_BEST_SELLING)}/>
                {items.map((i: Item) =>
                    <SimpleCard key={i.id}
                                id={i.id}
                                title_en={i.title_en}
                                title_sl={i.title_sl}
                                subtitle_en={i.subtitle_en}
                                subtitle_sl={i.subtitle_sl}
                                image={i.image}
                                promoted={i.promoted}
                                price={i.price!.toFixed(2)}
                                isSelected={this.isProductSelected(i.id!)}
                                onClick={() => this.handleItemSelection(i.id!)}
                    />
                )}
            </div>
        }
        return null;
    }

    renderItems() {
        const subcategories: Array<Category> = this.injected.home.categoriesState.data.filter((i: Category) => i.parent === this.injected.home.selectedCategory);

        if (subcategories.length > 0) {

            if (this.injected.home.selectedFilter !== -1) {
                const selectedID = this.injected.home.selectedFilter === -1 ? null : this.injected.home.selectedFilter;
                const items = this.injected.home.itemsState.data.filter((i: Item) => i.category === selectedID);

                return items.map((i: Item) =>
                    <SimpleCard key={i.id}
                                id={i.id}
                                title_en={i.title_en}
                                title_sl={i.title_sl}
                                subtitle_en={i.subtitle_en}
                                subtitle_sl={i.subtitle_sl}
                                image={i.image}
                                promoted={i.promoted}
                                price={i.price!.toFixed(2)}
                                isSelected={this.isProductSelected(i.id!)}
                                onClick={() => this.handleItemSelection(i.id!)}
                    />
                );
            }

            return subcategories.map((s: Category) => {
                const items = this.injected.home.itemsState.data.filter((i: Item) => i.category === s.id);

                let title = s.title_en;

                if (this.injected.application.language === Languages.slovenian) {
                    title = s.title_sl;
                }

                return <div key={s.id!}>
                    <Section title={title}/>
                    {this.renderSubcategoryDescription(s.id!)}
                    {items.map((i: Item) =>
                        <SimpleCard key={i.id}
                                    id={i.id}
                                    title_en={i.title_en}
                                    title_sl={i.title_sl}
                                    subtitle_en={i.subtitle_en}
                                    subtitle_sl={i.subtitle_sl}
                                    image={i.image}
                                    promoted={i.promoted}
                                    price={i.price!.toFixed(2)}
                                    isSelected={this.isProductSelected(i.id!)}
                                    onClick={() => this.handleItemSelection(i.id!)}
                        />
                    )}
                </div>
            });
        } else {
            const items = this.injected.home.itemsState.data.filter((i: Item) => i.category === this.injected.home.selectedCategory);

            return items.map((i: Item) =>
                <SimpleCard key={i.id}
                            id={i.id}
                            title_en={i.title_en}
                            title_sl={i.title_sl}
                            subtitle_en={i.subtitle_en}
                            subtitle_sl={i.subtitle_sl}
                            image={i.image}
                            promoted={i.promoted}
                            price={i.price!.toFixed(2)}
                            isSelected={this.isProductSelected(i.id!)}
                            onClick={() => this.handleItemSelection(i.id!)}
                />
            );
        }
    }

    render() {
        return (
            <div>
                {
                    this.showSearch && <FixedContainer>
                        <Search onClose={() => (this.showSearch = false)}/>
                    </FixedContainer>
                }
                {
                    this.showTable && <FixedContainer>
                        <Table onClose={() => (this.showTable = false)}/>
                    </FixedContainer>
                }
                <div className="main">
                    <Header onSearch={() => {
                        this.showSearch = true;
                    }}/>
                    {this.injected.home.categoriesState.isError && <StateError title={this.injected?.translation?.get(T_HOME_ERROR)} onRetry={() => this.injected.home.init()}/>}
                    {this.injected.home.categoriesState.isLoading && <Spinner/>}
                    {
                        !this.injected.home.categoriesState.isLoading && !this.injected.home.categoriesState.isError &&
                        <Wrapper>
                            <BackgroundRudder><img alt="" src={bgRudder}/></BackgroundRudder>
                            <CategoriesWrapper>
                                {this.renderCategories()}
                                {this.renderCategoryDescription()}

                            </CategoriesWrapper>
                            <CategoriesPromotedWrapper>
                                {this.renderPromoted()}
                            </CategoriesPromotedWrapper>
                            <FilterWrapper>
                                {this.renderFilter()}
                            </FilterWrapper>
                            <ItemsWrapper>
                                {this.renderItems()}
                            </ItemsWrapper>
                        </Wrapper>
                    }
                    <TableWrapper>
                        <MyTable onClick={() => {
                            this.showTable = true;
                        }}><img width={20} src={cocktail} alt="" /><span>{this.injected?.translation?.get(T_HOME_MY_TABLE)} ({this.injected.home?.total?.toFixed(2)} €)</span></MyTable>
                    </TableWrapper>
                    {!window.isEnkafe && <LanguageWrapper>
                       <Language onClick={() => {
                            this.injected.application.changeLanguage(Languages.english)
                        }}>
                            <LanguageFlag src={flagEN} />
                        </Language>
                        <Language onClick={() => {
                            this.injected.application.changeLanguage(Languages.slovenian)
                        }}>
                            <LanguageFlag src={flagSL} />
                        </Language>
                    </LanguageWrapper>}
                </div>
                <Vessel src={bgVessel} />
            </div>
        );
    }
}

export default Home;

const FixedContainer = styled.div`
  background: ${p => p.theme.app.background};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  margin-top: -50px;
  background: ${p => p.theme.app.background};
  padding-bottom: 100px;
`;

const ItemsWrapper = styled.div`
  padding: 0 1rem;
  height: 100%;
`;

const FilterWrapper = styled.div`
  margin-bottom: 1rem;
`;

const CategoriesWrapper = styled.div`
  padding: 1rem 0;
`;

const CategoriesPromotedWrapper = styled.div`
  padding: 0 1rem;
`;

const CategoryDescription = styled.div`
  margin: 1rem 1rem;
  color: ${p => p.theme.app.primary};
  text-align: center;
  line-height: 1.5;
  font-style: italic;
  font-size: 14px;
`;

const BackgroundRudder = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 208px;
  height: 102px;
  user-select: none;
  pointer-events: none;
`;

const LanguageWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
`;

const Language = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-left: 1rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const LanguageFlag = styled.img`
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
`;

const TableWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  z-index: 99;
`;

const MyTable = styled.div`
    background: ${p => p.theme.app.accent};
    color: ${p => p.theme.app.primary};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 1rem;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    
    span {
      padding-left: 10px;
    }
`;

const Vessel = styled.img`
    position: fixed;
    bottom: 0;
    z-index: 9;
    left: 0;
    pointer-events: none;
`;
