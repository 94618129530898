import ApplicationStore, {Languages} from "./Application";
import t_en from "../translations/translation_en";
import t_slo from "../translations/translation_sl";

export const T_SEARCH_RESULTS = "screen_search_results";
export const T_SEARCH_PLACEHOLDER = "screen_search_input_placeholder";
export const T_SEARCH_CLOSE = "screen_search_close";
export const T_HOME_FILTER = "screen_home_filter_by";
export const T_HOME_BEST_SELLING = "screen_home_best_selling";
export const T_HOME_MY_TABLE = "screen_home_my_table";
export const T_HOME_MENU = "screen_home_menu";
export const T_HOME_ERROR = "screen_home_error";
export const T_HOME_ERROR_RETRY = "screen_home_error_retry";
export const T_MISC_MIDNIGHT_PRICES = "screen_misc_midnight_prices";
export const T_TABLE_TOTAL = "screen_table_total";
export const T_TABLE_EMPTY = "screen_table_empty";

class TranslationStore {
    store: ApplicationStore;

    constructor(store: ApplicationStore) {
        this.store = store;
    }

    get = (key: string): string => {
        if (this.store.language === Languages.english) {
            return t_en[key];
        } else if (this.store.language === Languages.slovenian) {
            return t_slo[key];
        }

        return "";
    }
}

export default TranslationStore;
