import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (window.location.host === 'menu.enkafe.si') {
  window.isEnkafe = true;
  document.body.style.background = '#564833';
  document.title = 'Enkafe - Meni'
}

if (window.location.pathname.includes("print")) {
  document.body.classList.add('print')
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
