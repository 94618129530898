import axios, { AxiosInstance } from "axios";

class APIService {
    client: AxiosInstance;

    constructor() {
        let baseURL = "https://menu-api.captains-cabin.si/pricing"
        if (window.isEnkafe) {
            baseURL = "https://menu-api.captains-cabin.si/enkafe"
        }

        this.client = axios.create({
            headers: {},
            baseURL,
        });
    }

    categories = () => ({
        get: async (query = {}) => {
            return (await this.client.get(`/categories`, {
                params: query
            })).data;
        }
    });

    items = () => ({
        get: async (query = {}) => {
            return (await this.client.get(`/`, {
                params: query
            })).data;
        }
    });
}

export default APIService;
