class Item {
    id?: number;
    title_en?: string;
    title_sl?: string;
    subtitle_en?: string | null;
    subtitle_sl?: string | null;
    image?: string | null;
    category?: number | null;
    order?: number;
    status?: boolean;
    promoted?: boolean;
    price?: number;

    fromJSON(obj: Object) {
        let result: Item = new Item();
        for (let index in obj) {
            if (result.hasOwnProperty(index)) {
                // @ts-ignore
                result[index] = obj[index]; // care, has to be result
            }
        }
        return result;
    }
}

export default Item;
