import React from 'react';
import Home from './screens/Home';
import Print from './screens/Print';
import PrintWithNightPrice from './screens/PrintWithNightPrice';
import styled from 'styled-components';
import CaptainsTheme from "./themes/theme";
import { Provider } from 'mobx-react';
import HomeStore from "./stores/Home";
import ApplicationStore from "./stores/Application";
import TranslationStore from "./stores/translation";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

const GOOGLE_ANALYTICS_TRACKING_CODE = "UA-105723641-12";
const GOOGLE_ANALYTICS_4_TRACKING_CODE = "G-V4Z66R6KNY";
const GOOGLE_ANALYTICS_TRACKING_CODE_ENKAFE = "G-DQ6M48EKLR";

function App() {
  const home = new HomeStore();
  const application = new ApplicationStore();
  const translation = new TranslationStore(application);

  ReactGA.initialize(window.isEnkafe ? GOOGLE_ANALYTICS_TRACKING_CODE_ENKAFE : GOOGLE_ANALYTICS_TRACKING_CODE);
  ReactGA4.initialize(GOOGLE_ANALYTICS_4_TRACKING_CODE);

  const stores = {
    home,
    application,
    translation,
  }

  const isPrintWithNightPrice3 = window.location.pathname.includes("print3")
  const isPrintWithNightPrice2 = !isPrintWithNightPrice3 && window.location.pathname.includes("print2")
  const isPrint = !isPrintWithNightPrice2 && !isPrintWithNightPrice3 && window.location.pathname.includes("print")

  if (isPrint) {
    return <Provider {...stores} >
      <CaptainsTheme>
        <Print />
      </CaptainsTheme>
    </Provider>
  }

  if (isPrintWithNightPrice2) {
    return <Provider {...stores} >
      <CaptainsTheme>
        <PrintWithNightPrice nightPriceMultiplier={1.1} />
      </CaptainsTheme>
    </Provider>
  }

  if (isPrintWithNightPrice3) {
    return <Provider {...stores} >
      <CaptainsTheme>
        <PrintWithNightPrice nightPriceMultiplier={1.2} />
      </CaptainsTheme>
    </Provider>
  }

  return <Provider {...stores} >
    <CaptainsTheme>
      <Container hideOverflow={application.fixedScreenIsOpen}><Home /></Container>
    </CaptainsTheme>
  </Provider>;
}

const Container = styled.div<any>`
  background: ${p => p.theme.app.background};
  overflow: ${p => p.hideOverflow ? "hidden" : "visible"};
`;

export default App;
