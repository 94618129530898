import React, {ChangeEvent} from "react";
import {observable} from "mobx";
import styled from "styled-components";
import Section from "../components/Section";
import {inject, observer} from "mobx-react";
import stores from "../stores";
import TranslationStore, {T_SEARCH_CLOSE, T_SEARCH_PLACEHOLDER, T_SEARCH_RESULTS} from "../stores/translation";
import HomeStore from "../stores/Home";
import ApplicationStore, {Languages} from "../stores/Application";
import Item from "../models/Item";
import SimpleCard from "../components/SimpleCard";
import SearchIcon from "react-feather/dist/icons/search";
import CloseIcon from "react-feather/dist/icons/x";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import searchBG from "../assets/search-bg.png";

interface Props {
    onClose: Function;
}

interface StoreProps extends Props {
    translation: TranslationStore | null,
    home: HomeStore | null,
    application: ApplicationStore | null
}

@inject(stores.translation, stores.home)
@observer
class Search extends React.Component<Props> {
    @observable
    query = "";

    @observable
    results: Array<Item> = [];

    onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.query = e.target.value;
    }

    search() {
        if (this.query.trim().length === 0) return;
        const result = this.injected?.home?.search(this.query);

        const event = {
            category: 'Search',
            action: 'Search in menu',
            label: this.query.trim(),
        }

        ReactGA.event(event);
        ReactGA4.event(event);

        if (this.injected?.application?.language === Languages.slovenian) {
            this.results = result!.slo;
        } else {
            this.results = result!.eng;
        }

        // Send search event
    }

    get injected() {
        return this.props as StoreProps;
    }

    handleItemSelection = (id: number) => {
        this.injected.home?.toggleProductSelection(id);
    }

    isProductSelected = (id: number) => {
        return this.injected?.home?.isProductSelected(id);
    }

    render() {
        return <div>
            <Actions>
                <Close onClick={() => this.props.onClose()}>
                    <span>{this.injected?.translation?.get(T_SEARCH_CLOSE)}</span>
                    <CloseIcon size={16}/>
                </Close>
            </Actions>
            <Row>
                <SearchInput type="text" value={this.query} onChange={this.onQueryChange}
                             placeholder={this.injected?.translation?.get(T_SEARCH_PLACEHOLDER)}/>
                <Icon onClick={() => this.search()}><SearchIcon/></Icon>
            </Row>
            {this.results.length > 0 && <Section title={`${this.injected?.translation?.get(T_SEARCH_RESULTS)} (${this.results.length})`}/>}
            <Results>
                {this.results.map((i: Item) =>
                    <SimpleCard key={i.id}
                                title_en={i.title_en}
                                title_sl={i.title_sl}
                                subtitle_en={i.subtitle_en}
                                subtitle_sl={i.subtitle_sl}
                                image={i.image}
                                price={i.price!.toFixed(2)}
                                isSelected={this.isProductSelected(i.id!)}
                                onClick={() => this.handleItemSelection(i.id!)}
                    />
                )}
            </Results>
            <SearchBG src={searchBG} />
        </div>
    }
}

export default Search;

const SearchInput = styled.input`
  width: 100%;
  background: ${p => p.theme.app.backgroundLight} !important;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  color: ${p => p.theme.app.primary};
  font-size: 1rem;
  border: none;
  padding: 8px 1rem;
  height: 40px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  color: ${p => p.theme.app.primary};
  background: ${p => p.theme.app.backgroundLight};
  border-radius: 6px;
  cursor: pointer;
  height: 40px;
  margin-left: 1rem;
`;

const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.app.primary};
  border-radius: 6px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 1rem;
  border: 2px solid ${p => p.theme.app.primary};
  padding: 5px 0.5rem;
  user-select: none;
  
  span {
    margin-right: 1rem;
    vertical-align: middle;
    font-weight: bold;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
`;

const Results = styled.div``;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SearchBG = styled.img`
    position: fixed;
    z-index: 9;
    opacity: 0.1;
    bottom: 2rem;
    right: 2rem;
    width: 180px;
    pointer-events: none;
`;


